import { useAtom, atom } from "jotai";
import { Order } from "models";
import { usePathname, useRouter } from "next/navigation";
import { useEffect } from "react";
import { counselingStatusToPathMap } from "types";
import { counselingUncompleteAllowedRoutes } from "constant/routeConfig";

const hasRunOrderRedirectCheckAtom = atom(false);

/**
 * Returns a url that we should redirect to based on order properties.
 * Should return undefined if we should not redirect anywhere.
 * @param order
 * @returns URL
 */
export const getRedirectPath = (order: Order, pathname: string) => {
  const counselingStatus = order.moveType?.counselingStatus;
  const hasNotCompletedCounseling = order.isInOnboarding;

  // Cancelled
  if (order.status === "CANCELLED") {
    return "/";
  }

  // Counseling flows but allow users on certain pages to remain
  if (
    hasNotCompletedCounseling &&
    !counselingUncompleteAllowedRoutes.includes(pathname)
  ) {
    if (!counselingStatusToPathMap[counselingStatus]) {
      throw Error(
        `Missing a url redirect rule for ${counselingStatus} counseling status"`
      );
    }
    return counselingStatusToPathMap[counselingStatus];
  }
};

/**
 * This hook is responsible for routing the user to the correct
 * page as soon as an order is injected from the OrderLoader.
 * It is intended to only run once, on initialization of
 * the app.
 * It returns an object telling us if we have completed the
 * redirection process, and ready to render.
 * @param order The order loaded or null 
 * @param disable True if we should turn the hook off

 */
export const useOrderLoadRedirect = (order: Order | null, disable = false) => {
  const router = useRouter();
  const pathname = usePathname();
  const [hasRunOrderRedirectCheck, setHasRunOrderRedirectCheck] = useAtom(
    hasRunOrderRedirectCheckAtom
  );

  useEffect(() => {
    if (!hasRunOrderRedirectCheck) {
      if (disable) {
        setHasRunOrderRedirectCheck(true);
        return;
      }
      if (order) {
        const redirectPath = getRedirectPath(order, pathname!);
        if (redirectPath && pathname !== redirectPath) {
          router.replace(redirectPath);
        } else {
          setHasRunOrderRedirectCheck(true);
        }
      }
    }
  }, [order, hasRunOrderRedirectCheck, disable, pathname]);
  return { readyToRender: hasRunOrderRedirectCheck };
};
